<script>
  import { onMount } from "svelte";

  let stage = 0;
  let error = "";
  let disableButtons = false;

  let name;
  let mobile_no;

  let open = false;
  let openForm = false;
  let fadeOut = false;
  let z_9 = false;
  let z_99 = true;

  let message = "";
  let reward = 0.0;
  let rewardToGet = 0.0;

  let walletAddress = "";

  let successfulClaimText = "";

  onMount(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramsMobileNo = urlParams.get("mobile_no");
    if (paramsMobileNo) mobile_no = paramsMobileNo;
  });

  async function retrieveInfoFromNumber() {
    if (mobile_no.length != 8) {
      error = "Please enter a valid mobile number";
      return;
    }

    disableButtons = true;
    // error = "Boo 虎虎虎. Your reward is already claimed.";

    grecaptcha.enterprise.ready(function () {
      grecaptcha.enterprise
        .execute("6Lf2U0weAAAAADP6T3-e2cbbe-Vmmo4x6EBsanNK", {
          action: "submit",
        })
        .then(async function (token) {
          const res = await fetch(
            `https://asia-southeast2-angpao-339917.cloudfunctions.net/RetrieveInfo?mobileNumber=${mobile_no}&token=${token}`
          );
          disableButtons = false;
          const data = await res.json();
          if (data.error) {
            error = data.message;
            return;
          } else {
            if (!data.payload) {
              error = "Mobile number not found";
            }
            name = data.payload.Name;
            message = data.payload.Message;
            rewardToGet = data.payload.AmountToReceive;
            stage = 1;
          }
        });
    });
  }

  async function sendMoney() {
    disableButtons = true;
    //Validate wallet address
    if (!walletAddress.startsWith("0x")) {
      error = "Enter a valid wallet address";
      return;
    }
    const res = await fetch(
      "https://asia-southeast2-angpao-339917.cloudfunctions.net/SendMoney",
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          wallet_address: walletAddress,
          mobile_number: mobile_no,
        }),
      }
    );

    const data = await res.json();
    if (data.error) {
      error = data.message;
      return;
    } else {
      reward = 0;
      successfulClaimText = data.message;
    }
  }

  function clearError() {
    error = "";
  }

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function handleOpen() {
    open = true;
    z_99 = false;
    await sleep(500);
    openForm = true;
    z_9 = true;
    fadeOut = true;
    reward = rewardToGet;
  }

  async function handleClose() {
    openForm = false;
    await sleep(1000);
    open = false;
    fadeOut = false;
    z_9 = false;
    z_99 = true;
  }

  async function walletConnect() {
    if (typeof ethereum !== "undefined") {
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });

      walletAddress = accounts[0];
    } else {
      error = "Your browser does not support wallet connect";
    }
  }
</script>

<main>
  {#if stage == 0}
    <div class="angpao">
      <img class="intro_pic" src="/images/tiger-cartoon.png" alt="Tiger" />
      <br />
      <br />
      <h2>Enter your mobile number to claim your Ang Pao</h2>
      <p class="white_text">
        +65 <input
          class="mobile_no"
          bind:value={mobile_no}
          on:input={clearError}
          placeholder="Enter your mobile number"
        />
      </p>
      {#if error}
        <p class="red_text">
          {error}
        </p>
      {/if}

      <button
        class="retrieve_no"
        on:click={retrieveInfoFromNumber}
        disabled={disableButtons}>Go</button
      >
    </div>
  {:else if stage == 1}
    <div class="angpao">
      <h2>{name}! Here's your ang pao!</h2>
      {#if reward}
        <h2 class="yellow_text">You have received {reward} BNB!</h2>
        <p class="white_text">
          <!-- TODO: better instructions -->
          To claim it, enter your BEP20 (BSC) wallet address below. If you do not
          have one, download TrustWallet from the app store, and use the built in
          browser within the app.
        </p>

        <p class="white_text">
          <input
            class="wallet_address"
            bind:value={walletAddress}
            on:input={clearError}
            placeholder="Enter your BEP20 (BSC) wallet address"
          />
        </p>

        {#if error}
          <p class="red_text">
            {error}
          </p>
        {/if}

        <button
          class="wallet_connect"
          on:click={walletConnect}
          disabled={disableButtons}>Wallet Connect</button
        >

        <button
          class="retrieve_no"
          on:click={sendMoney}
          disabled={disableButtons}>Send me the money!</button
        >
      {/if}
      {#if successfulClaimText}
        <h2 class="smaller_text">{successfulClaimText}</h2>
      {/if}
      <div class="envelope">
        <div
          class="contact_form {openForm ? 'open_form' : ''} {z_9 ? 'z_9' : ''}"
        >
          <span class="reset" on:click={handleClose}>x</span>
          <div>
            <h2>Hey {name}!</h2>
            <div>
              <!-- 197 chars-->
              {message}
            </div>
          </div>
        </div>
        <div
          class="contact_me button {fadeOut ? 'fade_out' : ''}"
          on:click={handleOpen}
        >
          Open
        </div>

        <span
          class="top_flap {open ? '' : 'close_sesame'} {z_99 ? 'z_99' : ''}"
        />
        <!-- <span class="side_flaps" /> -->
        <span class="bottom_flap" />
      </div>
    </div>
  {/if}
</main>

<style>
  .angpao {
    text-align: center;
    padding-top: 5em;
    /* padding: 1em; */
    /* max-width: 240px; */
    margin: 0 auto;

    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  h2 {
    color: #ffffff;
    margin-block-start: 0;
  }

  @media (min-width: 640px) {
    main {
      max-width: none;
    }
  }

  .intro_pic {
    width: 30%;
    max-width: 300px;
  }
  .white_text {
    color: #ffffff;
  }

  .yellow_text {
    color: #fcd535;
  }

  .red_text {
    color: #dc3545;
  }

  .smaller_text {
    font-size: 1.2em;
  }

  .mobile_no {
    width: 250px;
  }

  .retrieve_no {
    background-color: #28a745;
    color: #ffffff;
  }

  .wallet_address {
    width: 70%;
  }

  .top_flap {
    top: -245px;
    left: 0px;
    overflow: hidden;
    position: absolute;
    height: 245px;
    width: 300px;
    -webkit-perspective: 500;
    -moz-perspective: 500;
    -ms-perspective: 500;
    -o-perspective: 500;
    perspective: 500;
    -webkit-transform-origin: bottom;
    -moz-transform-origin: bottom;
    -ms-transform-origin: bottom;
    -o-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transition: all 500ms 200ms linear;
    -moz-transition: all 500ms 200ms linear;
    -o-transition: all 500ms 200ms linear;
    transition: all 500ms 200ms linear;
    -webkit-transition-timing-function: cubic-bezier(0.6, 1.3, 0.2, 2.8);
    -moz-transition-timing-function: cubic-bezier(0.6, 1.3, 0.2, 2.8);
    -o-transition-timing-function: cubic-bezier(0.6, 1.3, 0.2, 2.8);
    transition-timing-function: cubic-bezier(0.6, 1.3, 0.2, 2.8);
    z-index: 1;
  }
  .close_sesame {
    -webkit-transform: rotatex(-180deg);
    -moz-transform: rotatex(-180deg);
    -ms-transform: rotatex(-180deg);
    -o-transform: rotatex(-180deg);
    transform: rotatex(-180deg);
  }

  .close_sesame.z_99 {
    z-index: 9999;
  }

  .top_flap:before {
    content: "";
    position: absolute;
    top: 90px;
    right: 150px;
    /* right: 248px; */
    /* width: 460px; */
    width: 70%;
    height: 380px;
    background: #eb6166;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    -webkit-transform: rotate(60deg);
    -moz-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    -o-transform: rotate(60deg);
    transform: rotate(60deg);
  }
  .top_flap:after {
    content: "";
    position: absolute;
    top: 90px;
    /* left: 248px; */
    left: 150px;
    /* width: 460px; */
    width: 70%;
    height: 380px;
    background: #eb6166;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    -webkit-transform: rotate(-60deg);
    -moz-transform: rotate(-60deg);
    -ms-transform: rotate(-60deg);
    -o-transform: rotate(-60deg);
    transform: rotate(-60deg);
  }
  .bottom_flap {
    bottom: 0px;
    left: 0px;
    position: relative;
    height: 500px;
    /* width: 800px; */
    width: 300px;
    /* overflow-y: hidden; */
    overflow: hidden;
    display: block;
    background: #f29c9f;
    background-image: url("/images/tiger.png");
    background-position-x: center;
    background-position-y: 200px;
    background-repeat: no-repeat;
    background-size: 70% auto;
    z-index: 10;
  }

  .envelope {
    position: relative;
    margin: 250px auto;
    /* width: 770px; */
    width: 300px;
    height: 400px;
    background: #ee787c;
    z-index: 999;
    margin-left: auto;
    margin-right: auto;
  }
  .envelope .button {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 240px;
    z-index: 999999999;
    font-size: 1rem;
    display: inline-block;
    margin: 0 auto;
    width: 60px;
    /* width: 250px; */
    height: 50px;
    /* border-bottom: 3px solid #9f1419; */
    text-align: center;
    color: #ffffff;
    background: #e5343a;
    cursor: pointer;
    /* -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px; */
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    line-height: 50px;
    border-radius: 50%;
  }

  .contact_form {
    padding-top: 30px;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    width: 250px;
    margin: 0 auto;
    height: 350px;
    background: #f6bcbe;
    -webkit-transition: all 800ms 200ms linear;
    -moz-transition: all 800ms 200ms linear;
    -o-transition: all 800ms 200ms linear;
    transition: all 800ms 200ms linear;
  }
  .open_form {
    -webkit-transition-timing-function: cubic-bezier(0.23, 0.69, 0.25, 0.9);
    -moz-transition-timing-function: cubic-bezier(0.23, 0.69, 0.25, 0.9);
    -o-transition-timing-function: cubic-bezier(0.23, 0.69, 0.25, 0.9);
    transition-timing-function: cubic-bezier(0.23, 0.69, 0.25, 0.9);
    height: 580px !important;
  }

  .z_9 {
    z-index: 9;
  }

  .reset {
    font-size: 20px;
    text-align: center;
    color: #9f1419;
    line-height: 20px;
    position: absolute;
    width: 20px;
    height: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    background: #f18e92;
    border: 4px solid #e84b50;
    top: -10px;
    right: -10px;
    cursor: pointer;
  }

  .contact_me {
    -moz-animation-name: fadeIn;
    -webkit-animation-name: fadeIn;
    -ms-animation-name: fadeIn;
    animation-name: fadeIn;
    -moz-animation-duration: 1s;
    -webkit-animation-duration: 1s;
    -ms-animation-duration: 1s;
    animation-duration: 1s;
    -moz-animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -moz-animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .contact_me.fade_out {
    -moz-animation-name: fadeOut;
    -webkit-animation-name: fadeOut;
    -ms-animation-name: fadeOut;
    animation-name: fadeOut;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      visibility: hidden;
    }
    100% {
      opacity: 1;
      visibility: visible;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
      visibility: visible;
    }
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }
</style>
